<template>
    <div align="center" class="justify-center">

    <!--    <div class="col-12 col-md-10 q-mt-sm" align="center">
            <QQButton label="MODIFICA" color="blue-grey" icon="mdi-update" size="sm"

            />
        </div>
        <hr> //-->
        <div class="text-h4">
            <br>
            Scheda Cliente {{ CapitalizeString(cliente_selezionato.cognome) }} {{ CapitalizeString(cliente_selezionato.nome) }}
        </div>
        <br>

        <div class="q-gutter-y-md" style="width: 100%">
            <q-card flat>
                <q-tabs
                    v-model="tab"
                    inline-label
                    align="center">
                        <q-tab name="dati_anagrafici" label="Dati anagrafici" />
                        <q-tab name="preventivi" label="Elenco Preventivi" class="text-weight-bolder" />
                        <q-tab name="pratiche" label="Elenco Pratiche" class="text-weight-bolder" />
                </q-tabs>



                <q-tab-panels v-model="tab" animated ref="tabs"
                    transition-prev="jump-down"
                    transition-next="jump-down">

                    <q-tab-panel name="dati_anagrafici">
                        <div class="row justify-center">
                            <div class="col-12 col-md-5">
                                <fieldset>
                                    <legend><strong>Informazioni generali</strong></legend>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="domanda">ID:</td>
                                                <td class="risposta">{{cliente_selezionato.id}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Tipo persona:</td>
                                                <td class="risposta">{{cliente_selezionato.tipo_persona}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Codice Fiscale:</td>
                                                <td class="risposta">{{cliente_selezionato.codice_fiscale}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Partita IVA:</td>
                                                <td class="risposta">{{cliente_selezionato.partita_iva}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Sesso:</td>
                                                <td class="risposta">{{cliente_selezionato.sesso}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Data di nascita:</td>
                                                <td class="risposta">{{cliente_selezionato.data_nascita}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Luogo di nascita:</td>
                                                <td class="risposta">{{cliente_selezionato.luogo_nascita}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">professione:</td>
                                                <td class="risposta">{{cliente_selezionato.altri_dati[0].professione}}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </fieldset>
                                <br>
                            </div>

                            <div class="col-12 col-md-5">
                                <fieldset>
                                    <legend><strong>Recapiti</strong></legend>
                                    <br>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Unit&agrave; Operativa</th>
                                                <th>Descrizione</th>
                                                <th>Valore</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="(altri_dati,i) in cliente_selezionato.altri_dati" :key="i">
                                            <tr v-for="(recapito,j) in altri_dati.recapiti" :key="j">
                                                <td>{{ limitaLength(altri_dati.unita_operativa) }}</td>
                                                <td>{{ recapito.tipo }}</td>
                                                <td>{{ recapito.value }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot v-if="cliente_selezionato.altri_dati.length === 0">
                                            <tr>
                                                <td colspan="3" align="center">Nessun recapito identificato</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <br>
                                </fieldset>
                                <br>
                            </div>

                            <div class="col-12 col-md-5">
                                <fieldset>
                                    <legend><strong>Indirizzi Residenza / Sede legale</strong></legend>
                                    <br>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Unit&agrave; Operativa</th>
                                                <th>Indirizzo</th>
                                                <th>Civico</th>
                                                <th>Comune</th>
                                                <th>Provincia</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(indirizzo,i) in indirizzi_residenza" :key="i">
                                                <td>{{ limitaLength(indirizzo.unita_operativa) }}</td>
                                                <td>{{ indirizzo.indirizzo }}</td>
                                                <td>{{ indirizzo.civico }}</td>
                                                <td>{{ indirizzo.comune }}</td>
                                                <td>{{ indirizzo.provincia }}</td>
                                            </tr>
                                            <tr v-if="indirizzi_residenza.length === 0">
                                                <td colspan="5" align="center">Nessun indirizzo identificato</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br>
                                </fieldset>
                                <br>
                            </div>

                            <div class="col-12 col-md-5">
                                <fieldset>
                                    <legend><strong>Indirizzi Corrispondenza</strong></legend>
                                    <br>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Unit&agrave; Operativa</th>
                                                <th>Indirizzo</th>
                                                <th>Civico</th>
                                                <th>Comune</th>
                                                <th>Provincia</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(indirizzo,i) in indirizzi_corrispondenza" :key="i">
                                                <td>{{ limitaLength(indirizzo.unita_operativa) }}</td>
                                                <td>{{ indirizzo.indirizzo }}</td>
                                                <td>{{ indirizzo.civico }}</td>
                                                <td>{{ indirizzo.comune }}</td>
                                                <td>{{ indirizzo.provincia }}</td>
                                            </tr>
                                            <tr v-if="indirizzi_corrispondenza.length === 0">
                                                <td colspan="5" align="center">Nessun indirizzo identificato</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br>
                                </fieldset>
                                <br>
                            </div>

                        </div>

                    </q-tab-panel>

                    <q-tab-panel name="preventivi">

                        <q-table
                            title="Clicca su un preventivo per attivare le opzioni di gestione"
                            :data="righe_preventivi_cliente"
                            :columns="colonne_preventivi_cliente"
                            :filter="filter"
                            dense
                            row-key="name"
                            @row-click="selezionaRecordPreventivi"
                            class="my-sticky-header-table no-box-shadow q-mt-none">
                            <template v-slot:top-right>
                                <div class="panel-cerca">
                                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                                        <template v-slot:append>
                                            <q-icon name="search" />
                                        </template>
                                    </q-input>
                                </div>
                            </template>

                            <template v-slot:header="props">
                                <q-tr :props="props">
                                    <q-th
                                        v-for="col in props.cols"
                                        :key="col.name"
                                        :props="props"
                                        style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                                        <strong>{{ col.label }}</strong>
                                    </q-th>
                                </q-tr>
                            </template>

                        </q-table>
                    </q-tab-panel>

                    <q-tab-panel name="pratiche">
                        <q-table
                            title="Clicca su una pratica per attivare le opzioni di gestione"
                            :data="righe_pratiche_cliente"
                            :columns="colonne_pratiche_cliente"
                            :filter="filter"
                            dense
                            row-key="name"
                             @row-click="selezionaRecordPratiche"
                            class="my-sticky-header-table no-box-shadow q-mt-none">
                            <template v-slot:top-right>
                                <div class="panel-cerca">
                                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                                        <template v-slot:append>
                                            <q-icon name="search" />
                                        </template>
                                    </q-input>
                                </div>
                            </template>

                            <template v-slot:header="props">
                                <q-tr :props="props">
                                    <q-th
                                        v-for="col in props.cols"
                                        :key="col.name"
                                        :props="props"
                                        style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                                        <strong>{{ col.label }}</strong>
                                    </q-th>
                                </q-tr>
                            </template>

                        </q-table>
                    </q-tab-panel>
                </q-tab-panels>
            </q-card>
        </div>
        <br><hr style='color: #fff;'>

        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
        </div>

        <br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import {mapFields} from "vuex-map-fields";
    import policy from "@/libs/policy";
    import { mapActions } from "vuex";

    export default {
        name: "SchedaCliente",
        components: {
            QQButton
        },
        data() {
            return {
                tab: 'dati_anagrafici',
                filter: "",
            }
        },
        methods: {
            ...mapActions({
                fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo",
                fetchElencoPreventivi: "formPreventivo/fetchElencoPreventivi",
                fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
                fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo"
            }),
            onIndietroClicked() {
                this.$router.push({name :"Clienti"});
            },
            CapitalizeString(myString) {
                return myString.trim().replace(/^\w/, (c) => c.toUpperCase());
            },
            isPreventivoModificabile() {
                if (policy.PuoModificarePreventiviPropri()) {
                    if (parseInt(policy.IdUnitaOperativa()) !== parseInt(this.RecordSelezionato["id_unita_operativa"])) {
                        return false
                    }
                }

                if (policy.PuoModificarePreventiviGruppo()) {
                    var UnitaOperativeSottostanti = policy.UnitaOperativeSottostanti();
                    if (!UnitaOperativeSottostanti.includes(this.RecordSelezionato["id_unita_operativa"])) {
                        return false;
                    }
                }

                var status = policy.PuoModificarePreventiviDiTutti() ||
                    policy.PuoModificarePreventiviPropri() ||
                    policy.PuoModificarePreventiviGruppo();

                return status;
            },
            async selezionaRecordPreventivi(event,row) {
                console.log("event: quando capisco come levarlo dai corbelli lo faccio fuori:",event);
                this.TipoRecordSelezionato = "Preventivo";
                this.RecordSelezionato = row;

                this.$store.commit("setArea",this.RecordSelezionato.area);

                if (this.isPreventivoModificabile()) {
                   await this.fetchDatiPreventivo(this.RecordSelezionato.numero);
                   await this.fetchElencoPreventivi(this.RecordSelezionato.area);

                   this.sezione_visualizzata = "Preventivi";
                   this.$router.push({name : "Preventivi.MenuGestionePreventivi"});

                } else {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Il preventivo selezionato non può essere modificato con le tue credenziali di accesso. '
                    });
                }
            },
            isPraticaModificabile() {
                if (policy.PuoModificarePraticheProprie()) {
                    if (parseInt(policy.IdUnitaOperativa()) !== parseInt(this.RecordSelezionatoPratiche["id_unita_operativa"])) {
                        return false
                    }
                }
                if (policy.PuoModificarePraticheGruppo()) {
                    var UnitaOperativeSottostanti = policy.UnitaOperativeSottostanti();
                    if (!UnitaOperativeSottostanti.includes(this.RecordSelezionatoPratiche["id_unita_operativa"])) {
                        return false;
                    }
                }
                return policy.PuoModificarePraticheDiTutti() ||
                    policy.PuoModificarePraticheProprie() ||
                    policy.PuoModificarePraticheGruppo();
            },
            async selezionaRecordPratiche(event,row) {
                console.log("event:",event);
                this.TipoRecordSelezionato = row.stato;
                this.RecordSelezionatoPratiche = row;

                // Verifica le policy prima di proseguire
                if (this.isPraticaModificabile()) {
                    let numero_pratica = row.numero_interno;

                    await this.fetchElencoDocumentiDaPreventivo(numero_pratica);
                    await this.fetchDatiPratica({ numero_pratica: numero_pratica, token: "" });

                    this.sezione_visualizzata = "Pratiche";
                    this.$router.push({name : "Pratiche.MenuGestionePratiche"});
                } else {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'La pratica selezionata non può essere modificata con le tue credenziali di accesso. '
                    });
                }
            },
            limitaLength(value) {
                if (value.length > 20) {
                    value = value.substring(0,20)+'...';
                }

                return value;
            }
        },
        computed: {
            indirizzi() {
                let indirizzi = this.cliente_selezionato.altri_dati.map((dati,i) => {
                    return dati.indirizzi.map((indirizzo,j) =>
                    {
                        indirizzo.id = ""+i+j,
                        indirizzo.id_unita_operativa = dati.id_unita_operativa,
                        indirizzo.unita_operativa = dati.unita_operativa;
                        return indirizzo;
                    });
                });
                return indirizzi.flat();
            },
            indirizzi_corrispondenza() {
                return this.indirizzi.filter(i =>  i.tipo === "corrispondenza");
            },
            indirizzi_residenza() {
                return this.indirizzi.filter(i =>  i.tipo === "residenza");
            },
            recapiti() {
                let recapiti = this.cliente_selezionato.altri_dati.map((dati,i) => {
                    return dati.recapiti.map((recapito,j) => {
                        recapito.id = ""+i+j,
                        recapito.id_unita_operativa = dati.id_unita_operativa;
                        return recapito;
                    });
                });
                return recapiti.flat();
            },
            ...mapFields("", {
                sezione_visualizzata: "sezione_visualizzata",
            }),
            ...mapFields("gestioneClienti", {
                cliente_selezionato: "cliente_selezionato",
                righe_pratiche_cliente: "righe_pratiche_cliente",
                colonne_pratiche_cliente: "colonne_pratiche_cliente",
                righe_preventivi_cliente: "righe_preventivi_cliente",
                colonne_preventivi_cliente: "colonne_preventivi_cliente"

            }),
            ...mapFields("gestionePreventivi", {
                RecordSelezionato: "RecordSelezionato",
            }),
            ...mapFields("gestionePratiche", {
                RecordSelezionatoPratiche: "RecordSelezionato",
            }),
            IndirizziResidenza() {
                //console.log("cliente_selezionato:",this.cliente_selezionato);
                var elenco_residenza = Array();

                this.cliente_selezionato.altri_dati.filter(dato => {
                    var indirizzi = dato.indirizzi.filter(indirizzo => {
                        return indirizzo.tipo === "residenza";
                    });

                    elenco_residenza.push(indirizzi);
                    return true;
                });

                return elenco_residenza;

            /*    var residenza = this.cliente_selezionato.indirizzi.filter(p => {
                    return p.tipo === "residenza";
                });

                return residenza[0]; */
            },
            IndirizzoCorrispondenza() {
                var corrispondenza = this.cliente_selezionato.indirizzi.filter(p => {
                    return p.tipo === "corrispondenza";
                });

                return corrispondenza[0];
            }
        }
    }
</script>

<style scoped>
    td.domanda {
        text-align: right;
        font-style: italic;
        font-weight: bold;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered td, .table th {
        border: 1px solid #404040;
        padding: 8px;
    }

    .table-bordered th {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 8px;
        text-align: left;
        color: #404040;
        background-color: #ffcc99;
        border: 1px SOLID #404040;
    }

    .panel-cerca {
        border: 1px SOLID #000;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 25px;
        color: #000;

    }

</style>
